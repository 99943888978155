<template>
  <div class="container-box">
    <el-table class="table-box" :data="tableData" row-key="id" :tree-props="{ children: 'tmpl_module_data', hasChildren: 'hasChildren' }" :header-cell-style="{ backgroundColor: ' #f3f4fa', color: ' #14141c' }">
      <el-table-column prop="exam_name" label="考试"></el-table-column>
      <el-table-column prop="tmpl_module_type_name" label="模块"></el-table-column>
      <el-table-column prop="class_name" label="班级"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-link :underline="false" type="primary" style="margin-right: 12px" @click="onScoreLook(scope.row, 'score')" v-if="!scope.row.tmpl_module_data">评分</el-link>
          <el-link :underline="false" type="success" @click="onScoreLook(scope.row, 'look')" v-if="!scope.row.tmpl_module_data" :disabled="!scope.row.is_has_score">查看评分</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="pw-page-pagination">
      <el-pagination background layout="prev, pager, next" :current-page.sync="currentPage" :page-size.sync="limit" @current-change="changePage" :total="total"> </el-pagination>
    </div>
  </div>
</template>

<script>
import { getdataAnalysis } from "../../../utils/apis";

export default {
  data() {
    return {
      currentPage: 1,
      limit: 10,
      total: 10,
      tableData: [],
    };
  },
  methods: {
    onScoreLook(item, type) {
      // console.log({ item, type });
      this.$router.push({ path: "/teacher/dataAnalysis/score", query: { type, exam_id: item.exam_id, tmpl_module_type: item.tmpl_module_type, tmpl_module_id: item.tmpl_module_id, title: item.tmpl_module_type_name } });
    },

    changePage(val) {
      this.currentPage = val;
      this.getTableList();
    },
    async getTableList() {
      let res = await getdataAnalysis({ paging: 1, page: this.currentPage, pageSize: this.limit, competition_id: this.$route.query.id });
      res.data.list.forEach((o) => {
        o.id = this.guid();
        o.tmpl_module_data.forEach((e) => {
          e.id = this.guid();
          e.exam_id = o.exam_id;
        });
      });
      this.tableData = res.data.list;
      this.total = res.data.total;
    },
    guid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
  },
  mounted() {
    this.getTableList();
  },
  watch: {
    $route(to, from) {
      // this.$router.go(0);
      this.getTableList();
    },
  },
};
</script>

<style lang="scss" scoped>
.container-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  background: #fff;
  border-radius: 10px;
  height: 100%;
  padding: 30px;
  .table-box {
    height: calc(100% - 40px);
    overflow-y: auto;
  }
}
</style>
